import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import tw from "twin.macro";

export const BackButtonWrapper = styled.a`
  ${tw`absolute rounded flex items-center border-2 border-white justify-center`}
  background-color: rgba(255, 255, 255, .5);
  width: 30px;
  height: 30px;
  left: 20px;
  top: 20px;
  cursor: pointer;
  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
  }

  button {
    ${tw`text-buttontext bg-primary font-bold p-4 text-lg rounded cursor-pointer w-full border-0`}
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;

    span {
      display: block;
    }
    span {
      font-size: 0.8rem;
    }
  }
`;

export const Price = styled.span`
  ${tw`p-4 fixed bottom-0 w-full`}
  background-color: #fff;
`;
