import React from 'react'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageHeader from "components/PageHeader/PageHeader";
import { withTranslation } from "react-i18next";

function Custom404({t}) {
  return (
    <div className="h-full flex flex-1 justify-center items-center flex-col">
    <img src="/Logo-Transparent.png" width={150} className="mb-6"/>
    <h1>Page not found</h1>
    <h4>Please check the link</h4>
  </div>
  );
}

export default withTranslation('common')(Custom404);