import React from 'react';

import { PageHeaderWrapper } from './PageHeader.style';
import { observer } from 'mobx-react';
import BackButton from 'components/BackButton/BackButton';

const PageHeader = observer(
  ({ title = '', action = null, showBack = true, goHome = false }) => {
    React.useEffect(() => {
      // console.log('BACK:', title, showBack, goHome)
    }, []);

    return (
      <PageHeaderWrapper>
        {showBack && <BackButton goHome={goHome} action={action} />}
        <h3>{title}</h3>
      </PageHeaderWrapper>
    );
  }
);

export default PageHeader;
